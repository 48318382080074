.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e0e0e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: 1em;
  /* calc(10px + 2vmin); */
  color: black;
}

.App-link {
  color: #61dafb;
}

.ArticlesPane {
  margin-top: 5px;
}
.Article {
  margin: 8px;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: solid #df7777 2px;
  max-width: 90%;
}
.ArticleTitleAndImage {
  align-items: center;
  -webkit-box-align :center;
  font-size: 1.1em;
  display: flex;
  padding: 6px;
  max-width: 90%;
}
.ArticleTitle {
  display: block;
}
.ArticleThumbDiv {
  margin-right: 6px;
}
.ArticleThumb {
  object-fit: cover;
  width:90px;
  height:90px;
  border: solid #888888 1px;
}
.ArticleTitleAndDate {

}
.ArticleDate {
  font-size: 0.6em;
}
.ArticleComments {
  margin-left: 8px;
  margin-right: 8px;
}

.Comment {
  max-width: 100%;
  /* border: solid 1px gray; */
  border-radius: 2px;
  background-color: #f5f5ff;
  margin-bottom: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}
.CommentStars {
  min-width: 60px;
  text-align: center;
  /* max-width: 120px; */
  /* padding-left: 8px; */
}
.CommentText {
  font-size: 0.9em;
  overflow-wrap: anywhere;
}
.CommentAuthor {
  font-size: 0.8em;
}
.Verdict {
  /* border: solid #dddddd 1px; */
  padding: 8px;
  margin-top: 8px;
  /* border-radius: 8px; */
  background-color: #fff9f9;
}
.VerdictControl {
  display :inline;
}
.VerdictCommentPane {
  margin-left: 8px;
  display: inline;
}
.VerdictCommentText {
  display: inline;
}
.VerdictForm {
  display: inline;
}
.VerdictAcceptRejectButton {
  margin-left: 4px;
}

.Toast {
  position: absolute;
  background-color: rgb(174, 185, 187);
  color:rgb(8, 20, 22);
  font-weight: 800;
  left: 0;
  right: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}
.ShowMoreButtonDiv {
  width: 100%;
  /* height: 72px; */
}
.ShowMoreButton {
  width: 100%;
  /* height: 72px; */
}
.PageDescription {
  margin: 4px;
}